#esrApprovalPage {
    display: grid;
    width: 98%;
    margin: 1%;
    /* margin-left: 4%;
    margin-top: 1%; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 15px;
    height: 100vh;
}

#esra_header {
    text-align: center;
}

#esra_info {
    text-align: center;
    padding-top: 10px;
    font-size: 3vh;
}

#esra_otherInfo > #esra_info {
    font-size: medium;
}

#esra_leftPane {
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    grid-column: 1 / 3;
    grid-row: 1 / 17;
}

#esra_topRight {
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    grid-column: 3 / 5;
    grid-row: 1 / 10;
}

#esra_creationDate {
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    grid-column: 3;
    grid-row: 10 / 13;
}

#esra_status {
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    grid-column: 4;
    grid-row: 10 / 13;
}

#esra_otherInfo {
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    grid-column: 3;
    grid-row: 13 / 16;
}

#esra_buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    grid-column: 4;
    grid-row: 13 / 16;
}

#esra_approve, #esra_pending {
    background-color: #029333;
    color: white;
    border: 2px solid black;
    border-radius: 7px;
    font-size: 2vh;
    height: 35%;
    width: 80%;
    margin: 1%;
}

#esra_reject, #esra_ongoing{
    background-color: #AF282E;
    color: white;
    border: 2px solid black;
    border-radius: 7px;
    font-size: 2vh;
    height: 35%;
    width: 80%;
    margin: 1%;
}

#esra_downloadESR {
    width: 100%;
    grid-column: 3 / 5;
    grid-row: 16;
}

#esra_downloadBtn {
    width: 100%;
    height: 100%;
    margin: 0px;
    color: white;
    border: 2px solid black;
    background-color: #AF282E;
    border-radius: 7px;
    font-size: 2vh;
}

textarea {
    box-shadow: none;
}
