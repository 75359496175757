:root {
    scroll-padding-top: 150px; /* ensure that the navbar does not obscure fields that are scrolled into view during form validation */
}

.select-labels {
    color: white;
    text-align: center; 
}

.topLevelSelects {
    margin-left: 10.5%;
    width: 50%;
    margin-left: 25%;
    z-index: 100;
}

@media only screen and (max-width: 1500px) {
    .equipmentSelects {
        height: 5vw;
    }    
}

#equipmentInfo {
    background-color: none;
    color: white;
    width:80%;
    /* height: 800px; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 0.5fr 0.5fr;
    gap: 10px;
    padding: 10px;
    margin-left: 10%;
    /* visibility: hidden; */
}

#equipmentInfo > div {
    background-color: white;
    border-radius: 10px;
    color: black;
    padding: 5px;
    /* box-shadow: 3px 3px black; */
}

#qrEquipmentLookup {
    display: grid;
    place-items: center;
}

#company_contact{
    grid-column: 1 / 4;
    grid-row: 1;
    border: 3px solid black;
}

#qr_code {
    grid-column: 4/5;
    grid-row: 1;
    border: 3px solid black;
}
#qr_code > div {
    width: fit-content;
    height: fit-content;
    margin: auto;
    position: relative;
}

#manufacturer{
    grid-column: 1 / 2;
    grid-row: 2;
    border: 3px solid black;
}

#model_number{
    grid-column: 2 / 3;
    grid-row: 2;
    border: 3px solid black;
}

#serial_number{
    grid-column: 3 / 4;
    grid-row: 2;
    border: 3px solid black;
}

#tag_number{
    grid-column: 4 / 5;
    grid-row: 2;
    border: 3px solid black;
}

#location{
    grid-column: 1 / 2;
    grid-row: 3;
    border: 3px solid black;
}

#area{
    grid-column: 2 / 3;
    grid-row: 3;
    border: 3px solid black;
}

#description{
    grid-column: 3 / 5;
    grid-row: 3;
    border: 3px solid black;
}

#control{
    grid-column: 1 / 2;
    grid-row: 4;
    border: 3px solid black;
    text-align: center;
}
#interlock{
    grid-column: 2 / 3;
    grid-row: 4;
    border: 3px solid black;
    text-align: center;
}
#alarm{
    grid-column: 3 / 4;
    grid-row: 4;
    border: 3px solid black;
    text-align: center;
}
#ind-rec{
    grid-column: 4 / 5;
    grid-row: 4;
    border: 3px solid black;
    text-align: center;
}

#insp_freq{
    grid-column: 1 / 2;
    grid-row: 5;
    border: 3px solid black;
}
#insp_last{
    grid-column: 2 / 3;
    grid-row: 5;
    border: 3px solid black;
}
#test_freq{
    grid-column: 3 / 4;
    grid-row: 5;
    border: 3px solid black;
}
#test_last{
    grid-column: 4 / 5;
    grid-row: 5;
    border: 3px solid black;
}

#company-select {
    width: 50%;
    text-align: center;
}

#equipment-select {
    width: 50%;
    text-align: center;
}

#initialValues { 
    display: flex;
    width: 80%;
    margin-left: 10%;
    margin-top: 2%;
}

#form-box {
    /* visibility: hidden; */
    width: 80%;
    margin-left: 10%;
    margin-top: 2%;
    padding: 5px;
}

.section-headers {
    color: white;
    width: 100%;
}

* select {
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
}

* label {
    display: inline-block;
    align-self: left;
    clear: left;
    width: 100%;
    text-align: left;
    color: white;
}
* input {
    width: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 5px 5px 5px;
    background-color: #fff; 
    border: 1px inset #ddd; 
    border-radius: 4px;
    display: inline-block;  
    align-self: left;
}

.calibrationLookup input[type="text"], .equipmentLookupinput [type="text"], .manualinput [type="text"],
.company input[type="text"], .contact input[type="text"], .config input[type="text"] {
    width: 80%;
    box-shadow: 3px 3px black;
}

#poc_select input[type="text"]{
    box-shadow: none;
}

.constant_section, .inspection, .docs, .switch_option, .trans_option,
.valve_option, .massflow_option, .vortexflow_option,.switch_trans_valve, 
.trans_valve, .flows, .trans_swtich {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px;
}

.trans_valve {
    margin-bottom: 1%;
}
.valve_comments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px;
}

#mass_flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px;
}

.col1 > input, .col2 > input, .col3 > input {
    width: 80%;
}

.col1 {
    flex: 33.3%;
}

.col2 {
    flex: 33.3%;
}

.col3 {
    flex: 33.3%;
}

.infoSection .column2 label{
    width: auto;
    padding-left: 5px;
}

* {box-sizing: border-box;}

#trans_testing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1%;
}
#valve_flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1%;
}

.column1_1 {
    flex: 50%;
}

.column2_2 {  
    flex: 50%
}

textarea {
    border: 1px solid black;
    background-color: white;
    box-shadow: 3px 3px black;
}

#equipment-header {
    width: 80%;
    margin-left: 10%;
}

#form-buttons, #submit {
    background-color: #AF282E;
    color: white;
    box-shadow: 3px 3px black;
    margin-top: 6px;
    margin-right: 10px;
}

#submit:hover, #generateQRCode:hover, #generateQRCodeButton:hover, #add-cal-button:hover  {
    box-shadow: 3px 3px black;
    transform: translateY(-0.25em);
}

.checkboxCSS {
    margin-right: 15px;
}

.checkboxCSS div {
    width: fit-content;
    height: fit-content;
    display: inline-block;

}

.checkboxCSS label {
    width: auto;
    margin-right: 10px;
    padding-right: 5px;
    padding-left: 5px;
}

.data-grid { 
    background-color: white;
    margin-bottom: 2%;
    border-radius: 5px;
    box-shadow: 3px 3px black;
}

.errorMessage {
    color: red;
    display: inline;
    margin-left: 10px;
}

.successMessage {
    color: green;
    display: inline;
    margin-left: 10px;
}

#top-header {
    width: 100%;
    text-align: center;
    color: white;
    margin-top: 1%;
    text-decoration: underline;
}

.addEquipment {
    background-color: #003045;
    color: white;
    box-shadow: none;
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.description {
    margin-top: 2%;
}

.inputs {
    margin-top: 2%;
}

.selectStatements {
    color: black;
}

.generalLabels {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2%;
}

.generalLabels  > input{
    width: 75%;
}

#addEquipmentForm {
    width: 80%;
    margin-left: 10%;
}

select {
    margin: 1%;
    box-shadow: 3px 3px black;
}

#instrumentDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
}

.column1, .column2 {
    width: 50%;
    flex: 50%;
    float: left;
}

#poc_select {
    width: 40%;
    color:black;
}

#poc-page-container {
    width: 80%;
    margin-top: 2%;
    margin-left: 10%;
}

#poc-company, .poc-company input[type="text"]{
    width: 80%;
    box-shadow: none;
}

#QRCode {
    display:none;
}

#generateQRCode {
    width: 128px;
    background-color: #AF282E;
    color: white;
    box-shadow: 3px 3px black;
}

#generateQRCodeButton {
    margin-left: 5px;
    background-color: #AF282E;
    color: white;
    box-shadow: 3px 3px black;
}

#add-cal-button {
    background-color: #AF282E;
    color: white;
    box-shadow: 3px 3px black;
    width: 70%;
    height: 50px;
    margin-left: 15%;
    margin-top: 1.5%;
}

#onoffline {
    width: 100%;
    text-align: center;
    color: white;
    margin-top: 1%;
    text-decoration: underline;
}

.multiple-value-text-input > input, .multiple-value-text-input > label {
    width: 100%;
} 

.multiple-value-text-input-item { 
    padding: 5px; 
    background-color: white;
    color: black;  
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 5px;
    display: inline-block;
}

.multiple-value-text-input-item-content {
    display: inline;
}

.multiple-value-text-input-item-delete-button { 
    cursor: pointer;display: inline;
}

.selectStatements input[type="text"] {
    box-shadow: none;
}

/* ESR */
.AddESR {
    padding: 2em;
}

.esr_info_section {
    display: flex;
    justify-content: space-between;
}

.esr_info_column1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 1.5em;
}

.esr_info_column2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 1.5em;
  
}
.esr_cost_container {
    display:flex;
    flex-direction: row;
    margin-bottom: 5px;
}
.esr_cost_section {
    display: flex;
    flex: .845;
    align-items: flex-end;
}

.esr_cost_column1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding-right: 3px;
}

.esr_cost_column2 {
    display: flex;
    flex-direction: column;
    flex: .2;
    padding-right: 3px;
}

.esr_cost_column3 {
    display: flex;
    flex-direction: column;
    flex: .2;
    padding-right: 3px;
}

.esr_cost_column4 { 
    display: flex;
    flex-direction: column;
    flex: .2;
}

.esr_cost_codes {
    flex: .15;
    width: 15%;
    align-self: flex-end;
    padding-left: .5%;
}

.esr_data_grid_container {
    display: flex;
}

.esr_data_grid { 
    flex: .85;
    background-color: white;
    margin-bottom: 2%;
    border-radius: 5px;
    box-shadow: 3px 3px black;
}

.cost_code_table_div {
    flex: .15;
    padding-left: .5%;
}

.cost_code_table {
    background-color: white;
    text-indent: .25em;
    font-size: x-large;
    border-radius: 7px;
    border: 1px solid black;
    box-shadow: 3px 3px black;
    width: 100%;
}

.cost_code_table tr {
    border-bottom: 1px solid black;
}

.esr_equipment_section {
    display: flex;
}

.esr_equipment_column1, .esr_equipment_column4 {
    display: flex;
    flex: .2;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 3px;
}
.esr_equipment_column3 {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 3px;
}

.esr_equipment_column2 {
    display: flex;
    flex: .2;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 3px;
}

.esr_task_section {
    display: flex;
    align-items: flex-end;
}

.esr_task_column1 {
    display: flex;
    flex-direction: column;
}

.esr_task_column1 label {
    margin-bottom: .8em;
}

.esr_task_column2 {
    display: flex;
    flex: .03;
    flex-direction: column;
    margin-left: 10px;
    padding-bottom: .25em;
}

.esr_task_column3 {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.esr_task_column4 {
    display: flex;
    flex: .5;
    flex-direction: row;
}

.esr_task_column4 input[type='text'] {
    margin-right: .1em;
}

.esr_task_column4, .esr_task_column3, .esr_task_column1, .esr_task_column2 label, input[type='text'] {
    margin-bottom: .3em;
    margin-top: auto;
}

.esr_task_column2 input[type='checkbox'] {
    height: 2em;
    width: 2em;
}   

#testing_table > tr > th {
    color: white;
    text-align: center;
}

#damping_text {
    margin-right: 15px;
}