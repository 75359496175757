.top-header {
    color: white;
    width: 100%;
    text-align: center;
    text-decoration: underline;
}

#company-dropdown {
    display: relative;
}

.data-grid { 
    background-color: white;
    margin-bottom: 2%;
    border-radius: 5px;
    box-shadow: 3px 3px black;
}

.equipmentCellContent {
    width: 100%;
    text-align: center;
}

.typeCheckboxEquipment {
    width: 50px;
    height: 50px;
}

#calibration-table {
    margin-top: 2%;
}

#progress-bar {
    width:80%;
    height:10px;
    margin-left: 10%;
    border-color: 2px solid black;
    background-color: white;
    visibility: hidden;
    border-radius: 5px;
}

#progress {
    visibility: visible;
    width: 0%;
    height: 10px;
    background-color: green;
    border-radius: 5px;
}

#loading {
    visibility: hidden;
    color: white;
    width: 100%;
    text-align: center;
}