.App {
  font-family: sans-serif;
  text-align: center;
}

#navbar {
  padding-left: 10px;
  background-color: #AF282E;
  border-bottom: 2px solid black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.navbar-nav {
  width:100%;
}

.navbar-toggler {
  float:right;
  margin-top: 2%;
  margin-right: 2%;
}

#drop {
  color: white;
}

#home-report {
  height: 1000px;
  width: 90%;
  margin-left: 5%;
  margin-top: 125px;
  background-color: #003045;
}

.container-video {
  position: absolute;
  top: 0px;
  z-index: -1;
  width: 100%;
}

#footer-vid {
  width: 100%;
}

#loginbox {
  width: 25%;
  height: 200px;
  background-color: white;
  margin-left: 37.5%;
  margin-top: 15%;
  text-align: center;
  padding-top: 1%;
  box-shadow: 3px 3px black;
  position: absolute;
}

#loginButton {
  width: 50%;
  background-color: #AF282E;
  color: white;
}

#loginButton:hover {
  box-shadow: 1px 1px black;
  transform: translateY(-0.25em);
}