:root {
  /* Some standardized alt colors. I'm not super happy with any of them, but they'll do for now. */
  --iss-red: #AF282E; /* from the brand standard */
  --iss-red-bright: #dc4f5a; /* Color Curve filter: ((0, 0), (95, 160), (255, 192)) */
  --iss-red-intense: #851e23; /* Color Curve filter: ((0, 0), (255, 192)) */
  --iss-red-intense-alt: #701013; /* Color Curve filter: ((0, 0), (160, 95), (255, 255)) */
  --iss-red-dull: #985457; /* Color Curve filter: ((0, 64), (255, 192)) */
  --iss-blue: #003045; /* from the brand standard */
  --iss-blue-bright: #005d7f; /* Color Curve filter: ((0, 0), (95, 160), (255, 192)) */
  --iss-blue-intense: #002434; /* Color Curve filter: ((0, 0), (255, 192)) */
  --iss-blue-intense-alt: #00141e; /* Color Curve filter: ((0, 0), (160, 95), (255, 255)) */
  --iss-blue-dull: #405863; /* Color Curve filter: ((0, 64), (255, 192)) */
}

.btn-custom-blue {
  color: white;
  background-color: var(--iss-blue);
  min-width: 7rem; /* force min width so buttons don't change shape when re-rendering */
}

.btn-custom-blue:focus {
  color: white;
  background-color: var(--iss-blue-intense-alt);
}

.btn-custom-blue:hover {
  color: white;
  background-color: var(--iss-blue-intense-alt);
}

.btn-custom-blue:disabled {
  color: white;
  background-color: var(--iss-blue-dull);
}

.btn-custom-red {
  color: white;
  background-color: var(--iss-red);
  min-width: 7rem; /* force min width so buttons don't change shape when re-rendering */
}

.btn-custom-red:focus {
  color: white;
  background-color: var(--iss-red-intense-alt);
}

.btn-custom-red:hover {
  color: white;
  background-color: var(--iss-red-intense-alt);
}

.btn-custom-red:disabled {
  color: white;
  background-color: var(--iss-red-dull);
}
