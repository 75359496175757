#logout-box {
    width: 50px;
    height: 60px;
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;
    padding-top: 13px;
    padding-left: 9px;
    margin-left: 20px;
}

#username {
    font-weight: bold;
}

#logo {
    background-color: white;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
}

#customer-logo {
    background-color: white;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    padding: 10px;
    margin-right: 30px;
}

span.navbar-brand > a, a.nav-link > a {
    color: white;
}

#bootstrap-override.nav-link {
    color: white;
}

a.dropdown-item > a {
    color:#003045
}

.alert {
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}