.manualLookup {
    background-color: whitesmoke;
    padding: 5px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
    border-radius: 5px;
    box-shadow: 5px 5px 5px black;
}

#manualHeader {
    width: 100%;
    text-align: center;
    margin-bottom: 1%;
}

#manualDropdowns {
    display: flex;
    flex-wrap: wrap;
}

.manual_inputs {
    width: 25%;
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
}

.pdfView {
    background-color: whitesmoke;
    margin: 3%;
    margin-top: 1%;
    margin-bottom: 1%;
    border: rgb(0, 0, 0) 2px solid;
    border-radius: 5px;
    box-shadow: 5px 5px 5px black;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 192px;
}

.pdfView p {
    text-align: center;
    margin: 5%;
}

.pdfView h4 {
    text-align: center;
    margin: 2%;
}

.error {
    text-align: center;
    color: red;
}



@media (max-width: 800px) {

}